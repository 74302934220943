<style lang="scss" scoped>
  :deep(.v-text-field) {
    .v-field__input {
      font-size: 2rem;
      color: $brandBlack;
      display: flex;
      letter-spacing: 2rem;
      font-family: 'Circular Std Bold';
      height: 56px;
      @media only screen and (min-width: 960px) {
        height: 80px;
      }
    }
  }
</style>

<template>
  <TextInput
    ref="el"
    maxlength="6"
    autocomplete="off"
    type="tel"
    label="Enter verification code"
    placeholder="______"
    :rules="codeRules"
    v-bind="$attrs"
  />
</template>

<script setup>

const el = ref();

function focus () {
  el.value.focus();
}

defineExpose({
  focus,
});

const codeRules = [
  value => value ? true : 'Code required',
  value => /[0-9]{6}/.test(value) ? true : 'Invalid code',
];

</script>
